class AdSpotlight extends HTMLElement {
    constructor() {
        super();
    }

    connectedCallback() {
        if (!this.classList.contains('mobile-collapsible'))
            return;

        this.elements = {
            btn: this.querySelector('.spotlight-content__btn'),
            btnText: this.querySelector('.spotlight-content__btn span'),
            btnIcon: this.querySelector('.spotlight-content__btn .fa'),
            panel: this.querySelector('.spotlight-content__body')
        }

        const mobileQuery = window.matchMedia("all and (min-width: 992px)");
        this.mediaQueryCheck(mobileQuery);

        mobileQuery.addEventListener("change", (e) => {
            this.mediaQueryCheck(e)
        });

        this.elements.btn.addEventListener('click', (e) => {
            this.togglePanel(e);
        });
    }

    togglePanel(e) {
        e.preventDefault();

        const isClosed = this.elements.panel.ariaHidden === "true";

        this.elements.btnText.textContent = isClosed
            ? this.elements.btn.dataset.closeText
            : this.elements.btn.dataset.openText;

        this.elements.btnIcon.classList.replace(
            isClosed ? "fa-chevron-down" : "fa-chevron-up",
            isClosed ? "fa-chevron-up" : "fa-chevron-down");

        this.elements.btn.ariaExpanded = isClosed;
        this.elements.panel.ariaHidden = !isClosed;
    }

    mediaQueryCheck(query) {
        if (!query.matches) {
            this.elements.btn.ariaExpanded = this.elements.btn.dataset.wasExpanded ?? false;
            this.elements.panel.ariaHidden = this.elements.panel.dataset.wasHidden ?? true;

            delete this.elements.panel.dataset.wasHidden;
            delete this.elements.btn.dataset.wasExpanded;

            return;
        }

        this.elements.btn.dataset.wasExpanded = this.elements.btn.ariaExpanded;
        this.elements.btn.ariaExpanded = query.matches;

        this.elements.panel.dataset.wasHidden = this.elements.panel.ariaHidden;
        this.elements.panel.ariaHidden = !query.matches;
    }
}

export default AdSpotlight