class AdToggleContent extends HTMLElement {
    constructor() {
        super();
    }

    connectedCallback() {
        this.elements = {
            heading: this.querySelector('.card-headline'),
            panel: this.querySelector('.card-expando'),
            button: this.querySelector('.toggle-card__controls'),
            icon: this.querySelector('.fa')
        };

        const mobileQuery = window.matchMedia("all and (min-width: 768px)");
        this.mediaQueryCheck(mobileQuery);

        this.elements.button.addEventListener('click', (e) => {
            this.toggleAccordionPanel(e)
        });

        mobileQuery.addEventListener("change", (e) => {
            this.mediaQueryCheck(e);
        });
    }

    toggleAccordionPanel(event) {
        event.preventDefault();

        const isClosed = this.elements.panel.ariaHidden === "true";

        this.elements.button.firstChild.nodeValue = isClosed
            ? this.elements.button.dataset.seeLessText
            : this.elements.button.dataset.seeMoreText;

        this.elements.icon.classList.replace(
            isClosed ? "fa-plus" : "fa-minus",
            isClosed ? "fa-minus" : "fa-plus");

        this.elements.button.ariaExpanded = isClosed;
        this.elements.panel.ariaHidden = !isClosed;

        (isClosed ? this.elements.panel : this.elements.heading).scrollIntoView({
            behavior: 'smooth'
        });
    }

    mediaQueryCheck(query) {
        if (query.matches) {
            this.elements.button.ariaExpanded = this.elements.button.dataset.wasExpanded ?? false;
            this.elements.panel.ariaHidden = this.elements.panel.dataset.wasHidden ?? true;

            delete this.elements.panel.dataset.wasHidden;
            delete this.elements.button.dataset.wasExpanded;

            return;
        }

        this.elements.button.dataset.wasExpanded = this.elements.button.ariaExpanded;
        this.elements.button.ariaExpanded = !query.matches;

        this.elements.panel.dataset.wasHidden = this.elements.panel.ariaHidden;
        this.elements.panel.ariaHidden = query.matches;
    }
}

export default AdToggleContent;