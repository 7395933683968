class AdTabs extends HTMLElement {
    constructor() {
        super();
    }

    connectedCallback() {
        this.tabButtons = this.querySelectorAll('.js-tab-link');
        this.tabButtons.forEach((button) => {
            button.addEventListener('click', () => this.setTab(button));
        });
        this.tabInfos = this.querySelectorAll('.js-tab-info');
    }

    setTab(button) {
        if (!button) {
            console.error('ATTab->setTab: button not found');
            return;
        }

        const tabInfo = this.querySelector(`#${button.dataset.id}`);

        if (!tabInfo) {
            console.error('ATTab->setTab: tab not found');
            return;
        }

        this.tabButtons.forEach((button) => button.classList.remove('selected'));
        button.classList.add('selected');

        this.tabInfos.forEach((tab) => tab.classList.add('hidden'));
        tabInfo.classList.remove('hidden');
    }
}

export default AdTabs;