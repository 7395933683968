import { Swiper } from 'swiper/bundle';
class AdSwiper extends HTMLElement {
    constructor() {
        super();
    }

    connectedCallback() {
        var centered = this.dataset.centered ? this.dataset.centered : false;
        var slidesPerView = this.dataset.slidesPerView ? this.dataset.slidesPerView : "auto";
        var next = this.querySelector('.swiper-next');
        var prev = this.querySelector('.swiper-prev');
        this.classList.add('swiper');
        var options = {
            // Optional parameters
            slidesPerView: slidesPerView,
            centeredSlides: centered,
            spaceBetween: 24,
            // If we need pagination
            pagination: {
                el: '.swiper-pagination',
                clickable: true
            }
        };
        if (next != null && prev != null) {
          options.navigation = {
            nextEl: ".swiper-next",
            prevEl: ".swiper-prev",
          }
        }
        this.swiper = new Swiper('.swiper', options);
    }
}

export default AdSwiper;