import AdTabs from './AdTabs';
import AdSwiper from './AdSwiper';
import AdToggleContent from './AdToggleContent';
import AdTabbedContent from './AdTabbedContent';
import AdForm from './AdForm';
import AdCollapse from './AdCollapse';
import AdAccordion from './AdAccordion';
import AdSpotlight from './AdSpotlight';

$(document).ready(function () {

    // Back To Top
    $('#backToTop').on('click', function () {
        var _this = $(this);
        $("html, body").stop(true, true);
        $("html, body").animate({ scrollTop: 0 }, 350, 'linear', function () {
            $(_this).blur();
        });
    }).on('mouseenter focus', function () {
        $(this).addClass('opacity-100');
    }).on('mouseleave blur', function () {
        $(this).removeClass('opacity-100');
    });

    $('#backToTop').on('focus', function () {
        var _this = $(this);
        $(this).bind('keyup.BackToTop', function (event) {
            if (event.which == 13) {
                $(_this).trigger('click');
            }
        });
    });

    $('#backToTop').on('blur', function () {
        $(this).unbind('keyup.BackToTop');
    });

    $('#backToTop').on('mouseleave', function () {
        $(this).blur();
    });

});

customElements.define('ad-tabs', AdTabs);
customElements.define('ad-swiper', AdSwiper);
customElements.define('ad-form', AdForm);
customElements.define('ad-toggle-content', AdToggleContent);
customElements.define('ad-tabbed-content', AdTabbedContent);
customElements.define('ad-accordion', AdAccordion);
customElements.define('ad-collapse', AdCollapse);
customElements.define('ad-spotlight', AdSpotlight);