class AdAccordion extends HTMLElement {
    constructor() {
        super();
    }

    connectedCallback() {
        this.cards = [...this.querySelector(".accordion > .accordion__cards")
            ?.children];

        for (const card of this.cards) {
            const title = card.querySelector(".accordion-card__title");
            title.addEventListener('click', (e) => this.handleCardClicked(e, card));
        }
    }

    handleCardClicked(event, card) {
        event.preventDefault();

        for (const card of this.cards.filter(x => x !== card)) {
            this.toggleCard(card, false);
        }

        const isExpanded = card.querySelector('.accordion-card__title').ariaExpanded === 'true';
        this.toggleCard(card, !isExpanded);
    }

    toggleCard(card, expanded) {
        const title = card.querySelector('.accordion-card__title');
        const icon = card.querySelector('.fa');
        const content = card.querySelector('.accordion-card__panel');

        title.ariaExpanded = expanded;
        content.ariaHidden = !expanded;

        if (expanded)
            icon.classList.replace('fa-plus', 'fa-minus');
        else
            icon.classList.replace('fa-minus', 'fa-plus');
    }
}

export default AdAccordion;