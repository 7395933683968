class AdTabbedContent extends HTMLElement {
    constructor() {
        super();
    }

    connectedCallback() {
        this.elements = {
            tabList: this.querySelector('[role="tablist"]'),
            tabControls: this.querySelectorAll('[role="tablist"] [role="tab"]'),
            tabs: this.querySelectorAll('[role="tabpanel"]')
        }

        this.focusedTabIndex = 0;

        for (const tabControl of this.elements.tabControls) {
            tabControl.addEventListener('click', (e) => this.tabControlClicked(e));
        }

        this.elements.tabList.addEventListener("keydown", (e) => {
            if (e.key !== "ArrowRight" && e.key !== "ArrowLeft")
                return;

            this.elements.tabControls[this.focusedTabIndex].setAttribute("tabindex", -1);

            if (e.key === "ArrowRight") {
                this.focusedTabIndex = this.focusedTabIndex + 1 < this.elements.tabControls.length
                    ? this.focusedTabIndex + 1
                    : 0;
            }

            if (e.key === "ArrowLeft") {
                this.focusedTabIndex = this.focusedTabIndex - 1 >= 0
                    ? this.focusedTabIndex - 1
                    : this.elements.tabControls.length - 1;
            }

            this.elements.tabControls[this.focusedTabIndex].setAttribute("tabindex", 0);
            this.elements.tabControls[this.focusedTabIndex].focus();
        });
    }

    tabControlClicked(e) {
        e.preventDefault();

        for (const tabControl of this.elements.tabControls) {
            tabControl.ariaSelected = (tabControl.getAttribute('aria-controls') === e.target.getAttribute('aria-controls')).toString();
        }

        for (const tab of this.elements.tabs) {
            if (tab.id !== e.target.getAttribute('aria-controls'))
                tab.setAttribute('hidden', '');
            else
                tab.removeAttribute('hidden');
        }
    }
}

export default AdTabbedContent;