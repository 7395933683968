/* 
    AdCollapse tag
    Required:
        div with class .js-collapse-button
        div with class .js-collapse-content
    Optional:
        data-start-open: determines if the collapsed section should start opened
        data-toggle-class: allows custom toggle class for animations and such
 */
class AdCollapsable extends HTMLElement {
    constructor() {
        super();
    }

    connectedCallback() {
        this.menuButton = this.querySelector('.js-collapse-button');
        this.menuDropdown = this.queryselector('.js-collapse-content');
        this.toggle = this.dataset.toggleClass ?? "hidden";
        this.menuButton?.addEventListener('click', (event) => this.toggleCollapse());
        this.menuDropdown?.classList.add(this.toggle);

        if (this.dataset.startOpen == "true") {
            this.toggleCollapse();
        }
    }

    toggleCollapse() {
        this.menuDropdown?.classList.toggle(this.toggle);
    }
}

export default AdCollapsable

/* 
    Example

    <ad-collapse data-start-open="false" data-toggle-class="hidden">
        <button class="btn btn-primary js-collapse-button">Toggle Collapse</button>
        <div class="js-collapse-content"></div>
    </ad-collapse?
*/