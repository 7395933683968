class AdForm extends HTMLElement {
    constructor() {
        super();
    }

    connectedCallback() {
        this.blockId = this.dataset.blockId;
        this.degreeSelect = this.querySelector(`#degree-${this.blockId}`);
        this.aosSelect = this.querySelector(`#aos-${this.blockId}`);
        this.programSelect = this.querySelector(`#programs-${this.blockId}`);
        this.extraQuestion = this.querySelector(`#extra-${this.blockId}`);
        this.continueButton = this.querySelector('.js-continue');
        this.backButton = this.querySelector('.js-back');
        this.submitButton = this.querySelector('.js-submit');
        this.form = this.querySelector('form');
        this.section1 = this.querySelector(`#section-1-${this.blockId}`);
        this.section2 = this.querySelector(`#section-2-${this.blockId}`);

        this.filterPrograms(this.dataset.aosFilter, this.dataset.degreeFilter);
        this.degreeSelect?.addEventListener('change', (event) => this.filterPrograms(this.aosSelect.value, this.degreeSelect.value));
        this.aosSelect?.addEventListener('change', (event) => this.filterPrograms(this.aosSelect.value, this.degreeSelect.value));
        this.programSelect?.addEventListener('change', (event) => this.showAdditionalQuestion());
        this.continueButton?.addEventListener('click', (event) => this.forwardStep(event));
        this.form?.addEventListener('submit', (event) => this.onSubmit(event));
        this.backButton?.addEventListener('click', (event) => this.backStep());
        this.submitButton?.addEventListener('click', (event) => this.validateAndSubmit(event));

        this.validateOnBlur('input[required]');
    }

    validateAndSubmit(event) {
        var valid = true;
        this.form.querySelectorAll('[required]').forEach((required) => {
            if (!this.validate(required)) {
                valid = false;
            }
        });
    }

    onSubmit(event) {
        event.preventDefault();
    }

    validateOnBlur(element) {
        this.querySelectorAll(element).forEach((element) => element.addEventListener('blur', (event) => this.validate(event.target)));
    }

    validate(element) {
        if (!element.reportValidity()) {
            console.log('validity report: ', element.reportValidity());
            element.classList.remove('valid');
            element.classList.add('invalid');
            return false;
        }
        element.classList.add('valid');
        element.classList.remove('invalid');
        return true;
    }

    validateSelect(element) {
        if (!element.value) {
            element.classList.remove('valid');
            element.classList.add('invalid');
            return false;
        }
        element.classList.add('valid');
        element.classList.remove('invalid');
        return true;
    }

    showErrorMessage(element, report) {

    }

    forwardStep(event) {
        event.preventDefault();
        let valid = true;
        this.section1.querySelectorAll('select[required]').forEach((select) => {
            if (!this.validateSelect(select)) {
                valid = false;
            }
        });

        this.section1.querySelectorAll('input[required]').forEach((input) => {
            if (!this.validate(input)) {
                valid = false;
            }
        });

        if (!valid) {
            return;
        }

        this.section1.classList.add('hidden');
        this.section2.classList.remove('hidden');
    }

    backStep() {

        this.section1.classList.remove('hidden');
        this.section2.classList.add('hidden');
    }

    filterPrograms(aos, degree) {
        if (!aos && !degree) {
            return;
        }

        this.programSelect.value = "";

        this.programSelect.querySelectorAll('option').forEach((option) => {
            if (this.checkAOS(aos, option) && this.checkDegree(degree, option)) {
                option.classList.remove('hidden');
                return;
            }
            option.classList.add('hidden');
        });

        this.programSelect.querySelectorAll('optgroup').forEach((optGroup) => {
            if (!this.checkAOS(aos, optGroup)) {
                optGroup.classList.add('hidden');
                return;
            }

            if (optGroup.querySelectorAll('option.hidden').length == optGroup.querySelectorAll('option').length) {
                optGroup.classList.add('hidden');
                return;
            }

            optGroup.classList.remove('hidden');
        });
    }

    checkAOS(aos, option) {
        if (!aos) {
            return true;
        }

        if ((option.dataset.aos == aos || option.dataset.aos == 'n/a')) {
            return true;
        }

        return false;
    }

    checkDegree(degree, option) {
        if (!degree) {
            return true;
        }

        if ((option.dataset.degree == degree || option.dataset.degree == 'n/a')) {
            return true;
        }

        return false;
    }

    showAdditionalQuestion() {
        var selectedOption = this.programSelect.options[this.programSelect.selectedIndex];

        if (!selectedOption.dataset.questionKey) {
            this.extraQuestion.classList.add('hidden');
            this.extraQuestion.removeAttribute('required');
            return;
        }
        
        this.extraQuestion.required = true;
        this.extraQuestion.classList.remove('hidden');
        this.extraQuestion.querySelectorAll('option').forEach((option) => {
            if (option.dataset.questionKey == selectedOption.dataset.questionKey) {

                if (option.dataset.selectionId) {
                    option.setAttribute('selected', true);
                }

                option.classList.remove('hidden');
                return;
            }

            option.classList.add('hidden');
        });
    }
}

export default AdForm;